<template>
  <div class="page-wrapper container">
    <h1 class="page-title" data-aos="fade-up">Events</h1>
    <div class="page-desc" data-aos="fade-up">
      <span>{{ description }}</span>
    </div>

    <div class="app-data-filter-wrapper">
      <AppDataFilterOpenButton @click="handleOpenFilter" />
    </div>

    <MsgEntityCollectionEmpty v-if="!loadingEvents && emptyEvents" name="Events" />

    <PageItemsGroup
      v-if="eventsInPerson.length"
      :base-type="'events'"
      :has-more="getMore.eventsInPerson"
      :items="eventsInPerson"
      :title="'in-person'"
      :type="'eventsInPerson'"
      @click-disabled="handleClickDisabledEvent"
      @load-more="loadMoreEvents"
    />
    <PageItemsGroup
      v-if="eventsVirtual.length"
      :base-type="'events'"
      :has-more="getMore.eventsVirtual"
      :items="eventsVirtual"
      :title="'virtual'"
      :type="'eventsVirtual'"
      @click-disabled="handleClickDisabledEvent"
      @load-more="loadMoreEvents"
    />
    <PageItemsGroup
      v-if="eventsInCaseYouMissedIt.length"
      :base-type="'events'"
      :has-more="getMore.eventsInCaseYouMissedIt"
      :items="eventsInCaseYouMissedIt"
      :title="'in case you missed it'"
      :type="'eventsInCaseYouMissedIt'"
      @click-disabled="handleClickDisabledEvent"
      @load-more="loadMoreEvents"
    />

    <AppDataFilterMain
      v-model="filterValues"
      :filter-groups="filterGroups"
      :init-open="isAppDataFilterOpened"
      :is-mobile="isMobileScreen"
      @input="handleChangeFilterValues"
      @open="handleOpenFilter"
      @close="handleCloseFilter"
    />

    <UpgradeFromEnthusiastMsgModal v-model="showUpgradeMembershipModal" />
  </div>
</template>

<script>
import { areRouteQueriesDifferent } from '@/helpers/routerHelper';
import { EntityTypes } from '@/helpers/entityType';
import { navigatorCurrentPosition } from '@/helpers/GeolocationHelper';
import EventDataFilterHelper from '@/helpers/data-filter/EventDataFilterHelper';

import pageMetadataQuery from '@/graphql/PageMetadata.query.gql';
import eventsPageQuery from '@/graphql/event/Events.query.gql';
import eventsPageFilterQuery from '@/graphql/event/EventsPageFilter.query.gql';

import AppDataFilterMain from '@/components/data-filter/AppDataFilterMain';
import PageItemsGroup from '@/components/partials/PageItemsGroup';
import AppDataFilterOpenButton from '@/components/data-filter/AppDataFilterOpenButton';
import MsgEntityCollectionEmpty from '@/components/MsgEntityCollectionEmpty.vue';
import UpgradeFromEnthusiastMsgModal from '@/components/modals/UpgradeFromEnthusiastMsgModal.vue';

const DATA_PER_PAGE = 6;
const EVENTS_IN_PERSON_TYPE = 'eventsInPerson';
const EVENTS_VIRTUAL_TYPE = 'eventsVirtual';
const EVENTS_IN_CASE_MISSED_TYPE = 'eventsInCaseYouMissedIt';

export default {
  name: 'EventsPage',
  components: {
    MsgEntityCollectionEmpty,
    AppDataFilterOpenButton,
    AppDataFilterMain,
    PageItemsGroup,
    UpgradeFromEnthusiastMsgModal,
  },
  metaInfo: {
    title: 'Events',
  },

  data() {
    return {
      description: '',
      eventsInPerson: [],
      eventsVirtual: [],
      eventsInCaseYouMissedIt: [],
      loadingEvents: true,
      getMore: {
        eventsInPerson: false,
        eventsVirtual: false,
        eventsInCaseYouMissedIt: false,
      },
      page: {
        eventsInPerson: 1,
        eventsVirtual: 1,
        eventsInCaseYouMissedIt: 1,
      },
      skipQuery: {
        eventsInPerson: false,
        eventsVirtual: false,
        eventsInCaseYouMissedIt: false,
      },
      filterGroups: [],
      filterValues: {},
      changingRouteAfterFilterUpdated: false,
      showUpgradeMembershipModal: false,
    };
  },

  computed: {
    emptyEvents() {
      return !this.eventsInPerson.length && !this.eventsVirtual.length && !this.eventsInCaseYouMissedIt.length;
    },
    filterQueryVariables() {
      return EventDataFilterHelper.prepareFilterValuesToQueryVariables(this.filterValues);
    },
    isAppDataFilterOpened() {
      return !!this.$store.state.isAppDataFilterOpened;
    },
    isMobileScreen() {
      return !!this.$store.state.isMobileScreen;
    },
    isEnthusiastMember() {
      return this.$store.state.museumRole;
    },
  },

  created() {
    navigatorCurrentPosition(
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', false);
      },
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', true);
      }
    );

    this.filterValues = EventDataFilterHelper.getFilterValuesFromRouteQuery(this.$route.query);
    this.fetchEvents();
  },
  mounted() {
    this.getFilterData();
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.changingRouteAfterFilterUpdated) {
      this.filterValues = EventDataFilterHelper.getFilterValuesFromRouteQuery(to.query);
    }
    this.resetFetchDataOptions();
    this.fetchEvents();
    this.changingRouteAfterFilterUpdated = false;
    next();
  },

  apollo: {
    pageMetadata: {
      query: pageMetadataQuery,
      variables() {
        return {
          entityType: EntityTypes.event,
        };
      },
      result(result) {
        this.description = result.data.pageMetadata?.description;
      },
    },
  },

  methods: {
    fetchEvents() {
      this.loadingEvents = true;
      this.$apollo
        .query({
          query: eventsPageQuery,
          fetchPolicy: 'no-cache',
          variables: {
            limit: DATA_PER_PAGE,
            pageInPerson: this.page.eventsInPerson,
            pageVirtual: this.page.eventsVirtual,
            pageInCaseYouMissed: this.page.eventsInCaseYouMissedIt,
            skipInPerson: this.skipQuery.eventsInPerson,
            skipVirtual: this.skipQuery.eventsVirtual,
            skipInCaseYouMissed: this.skipQuery.eventsInCaseYouMissedIt,
            ...this.filterQueryVariables,
          },
        })
        .then(({ data }) => {
          if (data.eventsInPerson) {
            this.eventsInPerson.push(...data.eventsInPerson.data);
            this.getMore.eventsInPerson = data.eventsInPerson.hasMorePages;
          }
          if (data.eventsVirtual) {
            this.eventsVirtual.push(...data.eventsVirtual.data);
            this.getMore.eventsVirtual = data.eventsVirtual.hasMorePages;
          }
          if (data.eventsInCaseYouMissedIt) {
            this.eventsInCaseYouMissedIt.push(...data.eventsInCaseYouMissedIt.data);
            this.getMore.eventsInCaseYouMissedIt = data.eventsInCaseYouMissedIt.hasMorePages;
          }
          this.loadingEvents = false;
        })
        .catch(() => {
          this.loadingEvents = false;
        });
    },
    getFilterData() {
      return this.$apollo
        .query({
          query: eventsPageFilterQuery,
          fetchPolicy: 'no-cache',
        })
        .then(({ data = {} }) => {
          this.filterGroups = EventDataFilterHelper.prepareFilterGroups(data, this.isEnthusiastMember);
        });
    },
    handleClickDisabledEvent() {
      this.showUpgradeMembershipModal = true;
    },
    handleOpenFilter() {
      this.$store.dispatch('toggleAppDataFilter', true);
    },
    handleCloseFilter() {
      this.$store.dispatch('toggleAppDataFilter', false);
    },
    handleChangeFilterValues() {
      const query = EventDataFilterHelper.setFilterValuesToRouteQuery(this.filterValues);

      if (areRouteQueriesDifferent(this.$route.query, query)) {
        this.changingRouteAfterFilterUpdated = true;
        this.$router.push({ name: this.$route.name, params: this.$route.params, query });
      }
    },
    loadMoreEvents(eventsType) {
      const skipQuery = {
        [EVENTS_IN_PERSON_TYPE]: true,
        [EVENTS_VIRTUAL_TYPE]: true,
        [EVENTS_IN_CASE_MISSED_TYPE]: true,
      };

      if (this.getMore[eventsType]) {
        skipQuery[eventsType] = false;
        this.skipQuery = skipQuery;
        this.page[eventsType]++;
        this.fetchEvents();
      }
    },
    resetFetchDataOptions() {
      this.loadingEvents = true;
      this.page.eventsInPerson = 1;
      this.page.eventsVirtual = 1;
      this.page.eventsInCaseYouMissedIt = 1;
      this.skipQuery = {
        eventsInPerson: false,
        eventsVirtual: false,
        eventsInCaseYouMissedIt: false,
      };
      this.eventsInPerson = [];
      this.eventsVirtual = [];
      this.eventsInCaseYouMissedIt = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  margin-top: 100px;
  padding-bottom: 60px;

  @media (max-width: 767px) {
    .open {
      position: fixed;
      top: 0;
      padding-top: 20px;
      left: 0;
    }
  }
}
.page-desc {
  max-width: 550px;
}
</style>
