<template>
  <div class="single-item-wrapper mb-5 position-relative" :class="{ 'single-item-wrapper--no-link': !routeTo }">
    <AppRouteLink :route-to="routeTo" @click="onClickDisabled">
      <div class="item-image-wrapper" :class="{ blurred: disabled }">
        <div class="item-image mb-3" :style="`background-image: url(${mediaUrl})`" />

        <EntityCardLabel v-if="entityCardLabel" :label="entityCardLabel" />
        <StaffPickLabel v-if="data.staff_pick" :v-align="entityCardLabelForStaffPickHorizAlign" />
      </div>

      <div class="title">{{ data.title }}</div>

      <template v-if="type === 'academy'">
        <div v-if="itemStartDate" class="details-line">
          <div v-if="itemStartDate" class="small-grey">{{ itemStartDate }}</div>
          <div v-if="itemEndDate && itemEndDate !== itemStartDate" class="small-grey pl-1">- {{ itemEndDate }}</div>
        </div>
      </template>

      <template v-else-if="type === 'art-fairs'">
        <div v-if="data.destination && !hide.destination" class="details-line">
          <div v-if="data.destination.title" class="small-grey pr-1">{{ data.destination.title }},</div>
          <div v-if="data.destination.country" class="small-grey">{{ data.destination.country }}</div>
        </div>
        <div v-if="itemStartDate" class="details-line">
          <div v-if="itemStartDate" class="small-grey">{{ itemStartDate }}</div>
          <div v-if="itemEndDate && itemEndDate !== itemStartDate" class="small-grey pl-1">- {{ itemEndDate }}</div>
        </div>
      </template>

      <template v-else-if="type === 'art-spaces'">
        <div class="details-line">
          <div v-if="data.destination && !hide.destination && data.destination.title" class="small-grey">
            {{ data.destination.title }}
          </div>
          <div v-if="data.type" class="small-grey pl-1">| {{ data.type }}</div>
        </div>
      </template>

      <template v-else-if="type === 'art-trips'">
        <div v-if="itemStartDate" class="details-line">
          <div v-if="itemStartDate" class="small-grey">{{ itemStartDate }}</div>
          <div v-if="itemEndDate && itemEndDate !== itemStartDate" class="small-grey pl-1">- {{ itemEndDate }}</div>
        </div>
      </template>

      <template v-else-if="type === 'destinations'">
        <div v-if="data && data.country" class="small-grey">{{ data.country }}</div>
      </template>

      <template v-else-if="type === 'exhibitions'">
        <div class="details-line">
          <span v-if="data.destination && !hide.destination && data.destination.title" class="small-grey">{{
            data.destination.title
          }}</span
          ><span v-if="data.artSpace && data.artSpace.title" class="small-grey pl-1">| {{ data.artSpace.title }}</span>
        </div>
        <div v-if="itemStartDate" class="details-line">
          <div v-if="itemStartDate" class="small-grey">{{ itemStartDate }}</div>
          <div v-if="itemEndDate && itemEndDate !== itemStartDate" class="small-grey pl-1">- {{ itemEndDate }}</div>
        </div>
      </template>

      <template v-else-if="type === 'events'">
        <div class="details-line" :class="{ blurred: disabled }">
          <div v-if="data.destination && !hide.destination && data.destination.title" class="small-grey">
            {{ data.destination.title }}
          </div>
          <div v-if="itemStartDate" class="small-grey pl-1">| {{ itemStartDate }}</div>
        </div>
        <div v-if="data.type" class="small-grey text-capitalize" :class="{ blurred: disabled }">{{ data.type }}</div>
      </template>

      <template v-else-if="type === 'historical-sites'">
        <div v-if="data.destination && !hide.destination" class="details-line">
          <div v-if="data.destination.title" class="small-grey">{{ data.destination.title }},</div>
          <div v-if="data.destination.country" class="small-grey pl-1">{{ data.destination.country }}</div>
        </div>
      </template>

      <template v-else-if="type === 'stories'">
        <div v-if="startPublishingAt" class="small-grey">{{ startPublishingAt }}</div>
      </template>
    </AppRouteLink>
  </div>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';
import { getEntityCardLabel, getEntityCardLabelForStaffPickHorizAlign } from '@/helpers/entityCardLabel';
import { formatDate } from '@/helpers/dateTimeHelper';

import AppRouteLink from '@/components/partials/AppRouteLink';
import StaffPickLabel from '@/components/partials/StaffPickLabel.vue';
import EntityCardLabel from '@/components/partials/EntityCardLabel.vue';

export default {
  name: 'EntityCard',
  components: { EntityCardLabel, StaffPickLabel, AppRouteLink },
  props: {
    data: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Object,
      default: () => ({
        destination: false,
      }),
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    entityCardLabel() {
      return getEntityCardLabel(this.data);
    },
    entityCardLabelForStaffPickHorizAlign() {
      return getEntityCardLabelForStaffPickHorizAlign(this.data);
    },
    itemStartDate() {
      let startDate = this.data.start_date || '';

      if (this.data.date?.length && this.data.date[0]) {
        startDate = this.data.date[0].start_date || '';
      }

      return formatDate(startDate);
    },
    itemEndDate() {
      let endDate = this.data.end_date || '';

      if (this.data.date?.length && this.data.date[this.data.date.length - 1]) {
        endDate = this.data.date[this.data.date.length - 1].end_date || '';
      }

      return formatDate(endDate);
    },
    mediaUrl() {
      return new ImageHandler().getFirstImgFromMedia(this.data);
    },
    routeTo() {
      return !this.disabled && this.data.slug ? getEntityRoute(this.data) : null;
    },
    startPublishingAt() {
      return formatDate(this.data.startPublishingAt);
    },
  },

  methods: {
    onClickDisabled() {
      if (!this.disabled) {
        return;
      }
      this.$emit('click-disabled');
    },
  },
};
</script>

<style lang="scss" scoped>
.item-image {
  &-wrapper {
    position: relative;
    margin-bottom: 1rem;
  }

  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #d9d9d9;
  transition: all 0.3s linear;

  @media (max-width: 767px) {
    height: 171px;
  }
}

.single-item-wrapper {
  &:not(&--no-link):hover {
    cursor: pointer;

    .item-image {
      transform: scale(0.97);
    }
  }

  .title {
    display: block;
    color: #353535;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.85px;
    text-transform: uppercase;
    word-break: break-word;
    line-height: 20px;

    @media (min-width: 768px) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  .small-grey {
    font-size: 14px;
    line-height: 20px;

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 25px;
    }
  }
}

.details-line {
  display: flex;
  flex-wrap: wrap;
}

.blurred {
  filter: blur(4px);
}
.item-image-wrapper.blurred {
  filter: blur(8px);
}
</style>
