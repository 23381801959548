<template>
  <section class="events-section">
    <h2 class="event-section-title text-uppercase" data-aos="fade-up">{{ title }}</h2>
    <div class="content-wrp row-considering-mobile">
      <EntityCard
        v-for="item in items"
        :key="`${type}-${item.id}`"
        :disabled="item.club_membership_level && museumRole"
        class="col-12 col-sm-6 col-lg-4"
        :data="item"
        data-aos="fade-up"
        :hide="hide"
        :type="baseType"
        @click-disabled="$emit('click-disabled')"
      />
    </div>
    <button v-if="hasMore" class="btn-to-load-more-events" data-aos="fade-up" @click="loadMore">Load more</button>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import EntityCard from '@/components/partials/EntityCard';

export default {
  name: 'PageItemsGroup',
  components: {
    EntityCard,
  },
  props: {
    baseType: {
      type: String,
      required: true,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Object,
      default: () => ({
        destination: false,
      }),
    },
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['museumRole']),
  },
  methods: {
    loadMore(e) {
      this.$emit('load-more', this.type);
      e.target.blur();
    },
  },
};
</script>
